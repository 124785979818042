:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    font-family: Tahoma;
    outline: none;
    border: none;
    cursor: pointer;
}

.light {
    background-color: none;
    color: white;
    transition: all 0.3s ease-out;
}

.dark {
    background-color: none;
    color: black;
    transition: all 0.3s ease-out;
}

.btn:hover {
    color: orange;
    transition: all 0.3s ease-out;
    text-decoration: underline orange;
}

@media screen and (max-width: 960px) {

    .btn {
        font-weight: bolder;
    }
    
}