.calendar-icon {
    position: relative;
    height: 70%;
    flex: 2;
}

.event-container {
    position: relative;
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F67653;
    padding: 15px 15px;
}

.date-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 40%;
    top: 0;
    left: 0;
    height: 70%;
    width: 100%;
    font-size: 1.5em;
}

.date-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.calendar-container {
    height: 100%;
    position: relative;
    display: flex;
    flex: 0;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
}

.month {
    height: 30%;
    flex: 1;
    box-sizing: border-box;
    font-size: 1.2em;
}

.date-text {
    color: #f4623a;
    z-index: 100;
}

.vertical {
    height: 80%;
    width: 1px;
    border-width: 0;
    background-color: white;
    text-align: center;
    margin: 0 15px;
    box-sizing: border-box;
}

.event-information-container {
    flex: 1;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-overflow: ellipsis;
}

.event-information-container h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.location-container p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.event-information-container p {
    margin: 0;
}

.event-information-container h2 {
    margin: 0;
}

.location-container {
    display: flex;
    align-items: center;
}

.location-icon {
   margin-right: 0.2em;
   margin-left: 0.2em;
}