.photoViewer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}

.photoController {
    display: flex;
    flex-direction: column;
}

.manageControls {
    box-sizing: border-box;
    height: 80px;
}

.manageControls.upper {
    display: flex;
    justify-content: flex-end;
    font-size: 0.5em;
    margin-right: 20px;
}

.middlePartControls {
    position: relative;
    height: calc(100vh - 160px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.photoNavigation {
    z-index: 10100;
    color: white;
    display: flex;
    align-items: center;
    font-size: 8em;
    box-sizing: border-box;
    margin: 0 10px;
    opacity: 0.8;
}

.picture {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

@media screen and (max-width: 960px) {
    .picture{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .picture img {
        width: 90%;
        height: 100%;
        object-fit: contain;
    }

    .middlePartControls {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}