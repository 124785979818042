.activityIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 10rem;
}

.image {
    flex: 1;
    margin: 10%, 20%, 0;
    font-size: 5rem;
}