.photoFrame {
    position: relative;
}

img {
    height: 100%;
    object-fit: cover;
    max-height: 100%;
}

.photoOverlay {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    color: white;
    transition: all 0.3s;
    background-color: rgba(244, 98, 58, 50%);
    opacity: 0;
}

.photoOverlay:hover {
    opacity: 1;
}

.photoOverlay svg {
    pointer-events: none;
}