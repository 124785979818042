.App {
  text-align: center;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #f4623a;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.noscroll { 
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navigation-bar {
  position: sticky;
  top: 0;
  z-index: 500;
}

.startingPage {
  display: flex;
  position: relative;
  top: -80px;
}

.informationSection {
  position: relative;
  top: -80px;
  background-color: #f4623a;
  color: white;
}

.informationSection hr {
  background-color: white;
}

.activitiesSection {
  position: relative;
  top: -80px;
  background-color: white;
  color: #f4623a;
}

hr {
  width: 20%;
  height: 5px;
  border: 0;
}

.activitiesSection hr {
  background-color: #f4623a;
}

.iconsGrid {
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(4, 200px);
  row-gap: 5vw;
  justify-content: center;
  align-items: center;
  margin: 5vw 0
}

.images-grid {
  display: block;
  height: 50vh;
}

.contact-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 960px) {
  .startingPage {
    flex-direction: column;
  }

  .contact-section {
    height: max-content;
  }

  .contact-icons {
    flex-direction: column;
  }

  .iconsGrid {
    display: flex;
    flex-direction: column;
  }
}
