.dailyMessage-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 50vw;
    background-size: cover;
    background-position: center;
    background-color: #555555;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url('../../resources/images/Betel_fullsize.png');
}

.daily-message-buttons {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
}

.dailyMessage {
    overflow: scroll;
    flex-direction: column;
    align-items: center;
    max-height: 80%;;
    width: 95%;
}

.dailyMessage.title {
    font-size: 3rem;
}

.dailyMessage.body {
    font-size: 1.15rem;
    color: rgba(255, 255, 255, 0.75);
    white-space: pre-line;
    overflow: visible;
}

.divider {
    width: 20%;
    height: 5px;
    color: #f4623a;
    background-color: #f4623a;
    border: 0;
}

.title {
    flex: 1;
    text-align: center;
    color: white;
}

.body {
    text-align: center;
    color: white;
    font-size: 1rem;
    font-weight: 600;
}

@media screen and (max-width: 960px) {

    .dailyMessage-container {
        width: 100vw;
    }
}