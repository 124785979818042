.events-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 50vw;
    color: white;
    background-size: cover;
    background-position: center;
    background-color: #555555;
    align-items: center;
    background-image: linear-gradient(rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%);
}

.events-list-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10%;
}

.events-list-container-empty {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.information-container {
    width: 60%;
    max-height: 50%;
    display: flex;
    justify-content: start;
    border-radius: 20px;
    background-color: #f4623a;
    overflow: hidden;
}

.events-list {
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
}



@media screen and (max-width: 960px) {

    .events-container {
        width: 100vw;
    }

    .information-container {
        width: 100%;
        border-radius: 0;
        max-height: 60%;
    }
}