.photoGallery {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-flow: nowrap !important;
    overflow-y: hidden;
    overflow-x: scroll;
}

.gallery-container {
    box-sizing: border-box;
    height: 100%;
    position: relative
}

.scroll-indicator-view {
    pointer-events: none;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.scroll-indicator-background {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: grey;
    background-color: grey;
    opacity: 0.3;
    z-index: 2;
}

.scroll-indicator-icons {
    position: absolute;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 3em;
    opacity: 1;
    z-index: 3;
    color: #F89478;
    overflow: hidden;
}

.arrow {
    height: 100%;
    width: 10rem;
    display: flex;
    align-items: center;
    position: relative;
    opacity: 1;
    transition: all 0.3s;
}

.arrow.left {
    justify-content: flex-start;
}

.arrow.right {
    justify-content: flex-end;
}

.arrow svg {
    padding: 0 0.8em;
    filter: drop-shadow(0 10px 5px rgb(0 0 0 / 0.8));
}

.arrow-background {
    position: absolute;
    height: 100%;
    width: 100%;
}

.arrow-background.left {
    background: radial-gradient(farthest-side at center left, rgba(0, 0, 0, 0.6) 0, transparent)
}

.arrow-background.right {
    background: radial-gradient(farthest-side at center right, rgba(0, 0, 0, 0.6) 0, transparent)
}

.arrow.hidden.right {
    transform: translateX(100%);
}

.arrow.hidden.left {
    transform: translateX(-100%);
}

.arrow.hidden {
    opacity: 0;
    transition: all 0.3s;
}