.login-container {
  position: fixed;
  background-color: #282c34;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-view {
  border-radius: 10px;
  background-color: white;
  min-width: 50%;
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  box-sizing: border-box;
}

.login-title {
  flex: 0
}

.login-form-container {
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 80%;
  box-sizing: border-box;
  margin-top: 5%;
}

@media screen and (max-width: 960px) {
  .login-view {
    width: 90%;
    padding: 10% 5%;
    min-height: 40%;
  }

  .login-form-container {
    margin: 0;
    justify-content: start;
  }
}