.informationSection {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20%;
    text-align: center;
    margin: 5% 0 9%;
}

@media screen and (max-width: 960px) {
    .container {
        padding: 0 20px;
    }
}