.text-change-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-buttons-container .button-input {
  margin: 10px;
}