body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
}

.logo {
  font-family: Merriweather;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  width: 50%;
}

ul {
  list-style-type: none;
}

li {
  width: auto;
  display: inline-block;
}

.headerButton {
  display: inline-block;
  width: 15%;
  box-sizing: border-box;
}

.navButtons {
  width: 50%;
  display: inline-block;
  text-align: center;
  height: inherit;
}

button {
  font-family: Merrywheather-sans;
  border: none;
  background: none;
  font-size: large;
  min-width: max-content;
}