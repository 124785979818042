.modal-view-blocker {
  position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: black;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modal-view-container {
  background-color: white;
  border-radius: 5px;
  width: 50vw;
  max-height: 90vh;
  box-sizing: border-box;
  padding: 2%;
  overflow: hidden;
}

.modal-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.modal-content {
  overflow: auto;
  white-space: pre-line;
}

.modal-top-bar svg {
  margin: 0px;
}

.modal-title {
  flex: 2;
}

.modal-title h1 {
  margin: 0;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

a {
  color: #f4623a;
}

@media screen and (max-width: 960px) {
  .modal-view-container {
    width: 90vw;
  }
}