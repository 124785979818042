.navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    background-color: #f4623a;
    transition: all 0.5s ease;
    padding: 0 20px;
    row-gap: 40px;
}

.transparent {
    background: rgba(0,0,0,0);
    transition: all 0.5s ease;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100%;
}

.navbar-logo {
    color: #000;
    justify-self: start;
    text-decoration: none;
    font-family: Merriweather;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    flex: 1;
    height: 100%;
    min-width: 20rem;
    text-align: center;
    align-items: center;
    text-transform: uppercase;
    z-index: 5;
    margin-right: 20px;
}

.nav-menu {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    grid-template-columns: repeat(4, 9vw);
    margin-left: 20px;
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 30vw;
}

.nav-item {
    height: 80px;
}

.menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-icon {
    display: none;
}

.white {
    color: white
}

ul {
    padding: 0;
    height: 100%;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .navbar-logo {
        position: absolute;
        justify-content: center;
        margin: 0;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        grid-gap: 30px;
        position: absolute;
        justify-content: flex-start;
        top: -100vh;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 499;
        margin: 0;
    }

    .nav-menu.active {
        background: #f4623a;
        position: absolute;
        top: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 501;
        opacity: 0.9;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: black;
        z-index: 10;
    }

    .menu-item {
        position: relative;
        top: 100px;
    }

    .menu-icon.xmark {
        right: 10px;
        z-index: 502;
    }
    
}