.edit-event-container {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin: 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

p {
  margin: 0;
}

.edit-event-information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-overflow: ellipsis;
  height: 100%;
  min-width: 0;
}

.edit-event-buttons {
  flex: 0;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.edit-event-button {
  padding: 10px;
}