.dashboard-container {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-height: 100vh;
}

.dashboard-inside-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 5vh;
}

.log-out-container {
  box-sizing: border-box;
  padding: 5px 0;
  height: 80px;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.log-out-container .button-input {
  margin-right: 20px;
}

.dashboard-blocking-view {
  background-color: white;
  border-radius: 10px;
  width: 60%;
  padding: 10px 20px;
}

.tab-content {
  padding: 10px;
}

@media screen and (max-width: 960px) {

  .dashboard-blocking-view {
    width: 80%;
  }
  
}