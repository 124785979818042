li {
  width: 100%;
}

.events-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.events-list-header .button-input {
  margin: 0;
}

.events-list-container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}